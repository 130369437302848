body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  margin: 0;
  padding: 20px;
}

.container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  text-align: center;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

input[type="text"], input[type="password"] {
  width: 90%;
  padding: 16px 20px;
  margin: 10px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
}

input[type="text"]:focus, input[type="password"]:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
  outline: none;
}

button {
  padding: 14px 20px;
  margin: 10px 0;
  border: none;
  cursor: pointer;
  width: 90%;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

button:hover {
  opacity: 0.8;
}

.connect-button {
  background-color: #28a745;
  color: white;
  font-size: 18px;
}

.send-button {
  background-color: #007bff;
  color: white;
}

.send-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

#status {
  margin-top: 20px;
}

#walletInfo {
  margin-top: 20px;
}

select {
  padding: 10px;
  margin: 10px 0;
  width: 90%;
  font-size: 16px;
}

.logo {
  width: 50px;
  height: 50px;
  margin: 10px;
}

.footer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.social-icon {
  width: 30px;
  height: 30px;
}